import React, { Fragment } from "react";
import { connect } from "react-redux";

import Breadcrumb from "../layout/Breaadcrumb";
import { URLS } from "../../util/constant";
import Zone from "./Zone";

class ZoneCreate extends React.Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb
          items={[
            {
              active: true,
              name: URLS.DASHBOARD_TEXT,
              url: URLS.DASHBOARD,
            },
            {
              active: true,
              name: URLS.ZONE_LIST_TEXT,
              url: URLS.ZONE_LIST,
            },
            {
              active: false,
              name: URLS.ZONE_CREATE_TEXT,
            },
          ]}
        />
        <Zone history={this.props.history}/>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ZoneCreate);
