import React from "react";
import { connect } from "react-redux";

import { alertResponseErrors } from "../../util/alert";
import { showLoading } from "../../redux/actions/siteAction";
import { getLog, clear } from "../../redux/actions/logAction";

class LogDetail extends React.Component {
  componentDidMount() {
    this.props.clear();
    const data = {
      id: this.props.match.params.id
    };
    this.props.getLog(data);
  }

  render() {
    const response = this.props.response;
    if (!response) {
      this.props.showLoading(true);
      return <div></div>;
    }

    this.props.showLoading(false);
    if (!response.success) {
      alertResponseErrors(response.errors);
    }

    const model = response.result;

    return (
      <table class="ui celled table ltr">
        <tbody>
          <tr class="positive">
            <td>meter_serial</td>
            <td>{model.meter_serial}</td>
          </tr>

          <tr class="positive">
            <td>active_total</td>
            <td>{model.active_total}</td>
          </tr>

          <tr class="positive">
            <td>active_1</td>
            <td>{model.active_1}</td>
          </tr>

          <tr class="positive">
            <td>active_2</td>
            <td>{model.active_2}</td>
          </tr>

          <tr class="positive">
            <td>active_3</td>
            <td>{model.active_3}</td>
          </tr>

          <tr class="positive">
            <td>active_4</td>
            <td>{model.active_4}</td>
          </tr>

          <tr class="positive">
            <td>reactive_total</td>
            <td>{model.reactive_total}</td>
          </tr>

          <tr class="positive">
            <td>reactive_1</td>
            <td>{model.reactive_1}</td>
          </tr>

          <tr class="positive">
            <td>reactive_2</td>
            <td>{model.reactive_2}</td>
          </tr>

          <tr class="positive">
            <td>reactive_3</td>
            <td>{model.reactive_3}</td>
          </tr>

          <tr class="positive">
            <td>reactive_4</td>
            <td>{model.reactive_4}</td>
          </tr>

          <tr class="positive">
            <td>combined_active_total</td>
            <td>{model.combined_active_total}</td>
          </tr>

          <tr class="positive">
            <td>combined_active_1</td>
            <td>{model.combined_active_1}</td>
          </tr>

          <tr class="positive">
            <td>combined_active_2</td>
            <td>{model.combined_active_2}</td>
          </tr>

          <tr class="positive">
            <td>combined_active_3</td>
            <td>{model.combined_active_3}</td>
          </tr>

          <tr class="positive">
            <td>combined_active_4</td>
            <td>{model.combined_active_4}</td>
          </tr>

          <tr class="positive">
            <td>demand</td>
            <td>{model.demand}</td>
          </tr>

          <tr class="positive">
            <td>voltage_1</td>
            <td>{model.voltage_1}</td>
          </tr>

          <tr class="positive">
            <td>voltage_2</td>
            <td>{model.voltage_2}</td>
          </tr>

          <tr class="positive">
            <td>voltage_3</td>
            <td>{model.voltage_3}</td>
          </tr>

          <tr class="positive">
            <td>time</td>
            <td>{model.time}</td>
          </tr>

          <tr class="positive">
            <td>date</td>
            <td>{model.date}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.log.getLogResponse
  };
};

const mapDispatchToProps = {
  getLog: getLog,
  clear: clear,
  showLoading: showLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(LogDetail);
