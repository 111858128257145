import { Api, setData } from "../../util/api";

export const getCategories =
  (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
      "category/get-categories",
      setData(data, getState)
    );

    dispatch({
      type: "GET_CATEGORIES",
      payload: {
        response: response.data,
      },
    });

    if (onResponse) {
      onResponse(response.data);
    }
  };
