import React, {Fragment} from 'react';
import {URLS} from "../../util/constant";
import {Link} from "react-router-dom";

class Breadcrumb extends React.Component {

    renderBreadcrumbItems = () => {
        if (!this.props.items) {
            return <div></div>;
        }

        return this.props.items.map(item => {
            return this.renderBreadcrumbItem(item);
        })
    }

    renderBreadcrumbItem = (item) => {
        let section = null;
        if (item.active) {
            section = <Link to={item.url} className="section">{item.name}</Link>;
        } else {
            section = <div className="active section">{item.name}</div>
        }
        return (
            <Fragment>
                {section}
                <div className="divider"> /</div>
            </Fragment>
        );
    }

    render() {
        return (
            <div className="ui raised segment">
                <div className="ui breadcrumb">
                    {this.renderBreadcrumbItems()}
                </div>
            </div>

        );
    }
}

export default Breadcrumb;