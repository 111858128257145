import React, {Fragment} from 'react';
import {Button,  Modal} from 'semantic-ui-react'
import {connect} from "react-redux";

import {handleSimpleModal} from "../../redux/actions/modalAction";

class SimpleModal extends React.Component {

    closeModal = () =>{
        if(this.props.simpleModal.onClose){
            this.props.simpleModal.onClose();
        }
        this.props.handleModal(false);
    }

    closeAndOperate =(onClick, onClose)=>{
        if(this.props.simpleModal.onClick){
            this.props.simpleModal.onClick();
        }

        if(this.props.simpleModal.onClose){
            this.props.simpleModal.onClose();
        }
        this.props.handleModal(false);
    }

    render() {
        return (
            <Fragment>
                <Modal
                    open={this.props.simpleModal.show}
                >
                    <Modal.Header>
                        {this.props.simpleModal.title}
                    </Modal.Header>
                    <Modal.Content>
                        <p>{this.props.simpleModal.description}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.closeModal}
                            negative>خیر</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='بله'
                            onClick={this.closeAndOperate}
                        />
                    </Modal.Actions>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    simpleModal: state.modal.simpleModal
})

const mapDispatchToProps = dispatch => ({
    handleModal: (visible) => {
        dispatch(handleSimpleModal(
            visible,
            '',
            '',
            null,
            null
        ))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleModal)