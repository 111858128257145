import React, { Fragment } from "react";
import { connect } from "react-redux";

import { showLoading } from "../../redux/actions/siteAction";
import { alertResponseErrors } from "../../util/alert";
import { URLS } from "../../util/constant";
import Breadcrumb from "../layout/Breaadcrumb";
import Package from "./Package";
import { clear, getPackage } from "../../redux/actions/packageAction";

class PackageUpdate extends React.Component {
  componentDidMount() {
    this.props.clear();
    const data = {
      id: this.props.match.params.id,
    };
    this.props.getPackage(data);
  }

  render() {
    const response = this.props.response;
    if (!response) {
      this.props.showLoading(true);
      return <div></div>;
    }

    if (!response.success) {
      this.props.showLoading(false);
      alertResponseErrors(response.errors);
      return <div></div>;
    }

    this.props.showLoading(false);

    const categoryId = this.props.match.params.categoryId;

    return (
      <Fragment>
        <Breadcrumb
          items={[
            {
              active: true,
              name: URLS.DASHBOARD_TEXT,
              url: URLS.DASHBOARD,
            },
            {
              active: true,
              name: URLS.PACKAGE_LIST_TEXT,
              url: URLS.PACKAGE_LIST,
            },
            {
              active: false,
              name: URLS.PACKAGE_UPDATE_TEXT,
            },
          ]}
        />
        <Package categoryId={categoryId} model={response.result} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.package.getPackageResponse,
  };
};

const mapDispatchToProps = {
  getPackage: getPackage,
  clear: clear,
  showLoading: showLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageUpdate);
