import Axios from 'axios';
import {CONSTANT} from "./constant";

export const Api = Axios.create({
    baseURL: CONSTANT.SERVER_URL
})

export const setData = (data, getState) => {
    if (data) {       
        if (data instanceof FormData) {
            data.append('token', getState().auth.token);
        } else {
            data = {
                ...data,
                token: getState().auth.token
            }
        }
    } else {
        data = {
            token: getState().auth.token
        }
    }

    return data;
}