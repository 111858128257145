export const showLoading = (show) => ({
    type: 'HANDLE_LOADING',
    payload: {
        show: show
    }
})

export const changeSideMenuIndex = (index) => ({
    type: 'CHANGE_SIDE_MENU_INDEX',
    payload: {
        sideMenuIndex: index
    }
})

export const setTitle = (title) => ({
    type: 'SET_TITLE',
    payload: {
        title: title
    }
})

export const showSideMenu = (show) => ({
    type: 'HANDLE_SIDE_MENU',
    payload: {
        show: show
    }
})

export const setNotifications = (response) => ({
    type: 'SET_NOTIFICATIONS',
    payload: {
        response: response
    }
})
