import Numeral from "numeral";
import PersianDate from "./PersianDate";
import {CONSTANT} from "./constant";
import React from "react";

export const formatNumber = (number) => {
    return Numeral(number).format("0,0")
}

export const timeValidation = (input) => {
    const regex = new RegExp("([0-1]?[0-9]|2[0-4]):([0-5][0-9])");
    return regex.test(input);
}

export const persianDateValidation = (date) => {
    const result = PersianDate.getGregorianDateFromPersianDate(date);
    if (result) {
        return true;
    } else {
        return false;
    }
}

export const renderDate = (date, format = 'YYYY/MM/dd') => {
    date = parseInt(date);
    return PersianDate.getPersianDateFromGregorianTimeStamp((date * 1000), format);
}

export const getImagePath = (path) => {
    return CONSTANT.SERVER + path;
}

/*package*/
export const renderPackageStatus = (type) => {
    type = parseInt(type);
    switch (type) {
        case CONSTANT.PACKAGE_STATUS_ACTIVE:
            return <strong>{CONSTANT.PACKAGE_STATUS_ACTIVE_TEXT}</strong>;
        case CONSTANT.PACKAGE_STATUS_DELETED:
            return <strong>{CONSTANT.PACKAGE_STATUS_DELETED_TEXT}</strong>;
        default:
            return "";
    }
}
