import {Api} from "../../util/api";
import {clearData, getData, setData} from "../../util/dbHelper";
import {DB_KEYS} from "../../util/constant";

export const checkLogin = () => async (dispatch) => {
    const token = getData(DB_KEYS.TOKEN);
    const user = getData(DB_KEYS.USER);
    if (user) {
        dispatch({
            type: 'CHECK_LOGIN',
            payload: {
                user: user,
                token: token
            }
        });
    } else {
        dispatch({
            type: 'CHECK_LOGIN',
            payload: {
                user: null,
                token: null
            }
        });
    }
}

export const login = (username, password, onResponse) => async (dispatch) => {
    const response = await Api.post(
        'auth/login',
        {
            username: username,
            password: password
        }
    );

    if (response.data.success) {
        const user = response.data.result.user;
        const token = response.data.result.token;
        setData(DB_KEYS.USER, user);
        setData(DB_KEYS.TOKEN, token);
    }

    dispatch({
        type: 'LOGIN',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const logout = (onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'user/logout',
        {
            token: getState().auth.token,
        }
    );

    clearData(DB_KEYS.USER);
    clearData(DB_KEYS.TOKEN);

    dispatch({
        type: 'LOGOUT',
        payload: {
            user: null,
            token: null
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}
