import React from 'react';
import {connect} from 'react-redux';

import {showLoading} from "../../redux/actions/siteAction";
import Dropzone from "react-dropzone";
import {getImagePath} from "../../util/helper";
import {deleteFile} from "../../redux/actions/fileAction";
import fileTemplateFile from "../../asset/images/file-icon/file.png";
import fileTemplatePdf from "../../asset/images/file-icon/pdf.png";
import fileTemplateZip from "../../asset/images/file-icon/zip.png";

class File extends React.Component {

    state = {
        files: []
    }

    componentDidMount() {
        const existFiles = this.props.files;
        if (existFiles) {
            const files = existFiles.map(file => {
                return {
                    id: file.id,
                    path: file.path,
                    name: file.real_name,
                    extension: file.extension,
                }
            });
            this.setState({
                files: files
            });
        }
    }

    onDrop = (files) => {
        if (!files) {
            return;
        }

        const arraySize = files.length;
        for (let i = 0; i < arraySize; i++) {
            const file = files[i];
            this.onFileSelected(file);
        }
    }

    onFileSelected = (file) => {
        try {
            if (file) {
                const reader = new FileReader();
                reader.onerror = (error) => {
                    console.log('file reading has failed');
                    console.log('file error', error);
                };
                reader.onload = () => {
                    file.data = reader.result;
                    this.addFile(file);
                };
                reader.readAsDataURL(file);
            }
        } catch (error) {
            error(error.message);
        }
    }

    addFile = (file) => {
        const files = this.state.files;
        files.push(file);
        this.setState({
            files: files
        });
        if (this.props.onFileSelect) {
            this.props.onFileSelect(this.state.files);
        }
    }

    printImages = () => {
        let files = this.state.files;
        if (!files || files.length == 0) {
            files = this.props.files;
            if (!files || files.length == 0) {
                return <div></div>;
            }
        }

        return files.map((file, index) => {
            let data = null;
            if (file.id) {
                switch (file.extension) {
                    case 'png':
                    case 'jpg':
                    case 'jpeg':
                        data = getImagePath(file.path);
                        break;
                    case 'pdf':
                        data = fileTemplatePdf;
                        break;
                    case 'zip':
                        data = fileTemplateZip;
                        break;
                    default:
                        data = fileTemplateFile;
                }

            } else {
                switch (file.type) {
                    case 'image/png':
                    case 'image/jpg':
                    case 'image/jpeg':
                        data = file.data;
                        break;
                    case 'application/pdf':
                        data = fileTemplatePdf;
                        break;
                    case 'application/zip':
                        data = fileTemplateZip;
                        break;
                    default:
                        data = fileTemplateFile;
                }
            }
            return (
                <div className="card" key={index}>
                    <div className="image">
                        <img src={data} alt={file.name}/>
                    </div>
                    <div
                        className="ui bottom attached button red"
                        onClick={() => {
                            this.deleteImage(index)
                        }}
                    >
                        <i className="remove icon"></i>
                        حذف
                    </div>
                </div>
            );
        });
    }

    deleteImage = (index) => {
        let files = this.state.files;
        if (!files || files.length === 0) {
            files = this.props.files;
        }
        const file = files[index];
        if (file.id) {
            const data = {
                id: file.id
            };
            this.props.deleteFile(data);
        }
        files.splice(index, 1);
        this.setState({
            files: files
        });
    }

    render() {
        let description = "برای بارگذاری فایل کلیک کنید یا فایل های خود را به این قسمت بکشید";
        let accept = "image/jpeg, image/png";
        if (this.props.description) {
            description = this.props.description;
        }

        if (this.props.accept) {
            accept = this.props.accept;
        }

        return (
            <div className="ui grid one column stackable">
                <div className="row">
                    <div className="column">
                        <Dropzone onDrop={this.onDrop}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps({
                                            accept: accept
                                        })} />
                                        <p className="dz-p">
                                            {description}
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
                <div className="ui link cards">
                    {this.printImages()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = {
    showLoading: showLoading,
    deleteFile: deleteFile,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(File);
