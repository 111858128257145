import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { CONSTANT, URLS } from "../../util/constant";
import Pagination from "../layout/Pagination";
import { alertResponseErrors } from "../../util/alert";
import { showLoading } from "../../redux/actions/siteAction";
import Breadcrumb from "../layout/Breaadcrumb";
import { getCategories } from "../../redux/actions/categoryAction";

class CategoryList extends React.Component {
  state = {
    currentPage: 1,
  };

  componentDidMount() {
    this.getCategories(this.state.currentPage);
  }

  renderTable = () => {
    return (
      <Fragment>
        <table className="ui selectable celled table right aligned">
          <thead>
            <tr>
              <th>دسته</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </Fragment>
    );
  };

  renderRows() {
    const response = this.props.response;
    if (!response || !response.success) {
      return <div></div>;
    }

    const packages = response.result;
    if (!packages) {
      return <div></div>;
    }

    return packages.map((item) => {
      const packagesUrl = URLS.PACKAGE_LIST.replace(":categoryId", item.id);

      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>
            <Fragment>
              <Link to={packagesUrl}>
                <button
                  title="مشاهده پکیج ها"
                  className="circular ui icon button blue"
                >
                  <i className="icon eye"></i>
                </button>
              </Link>
            </Fragment>
          </td>
        </tr>
      );
    });
  }

  getCategories = (page) => {
    this.setState({
      currentPage: page,
    });

    const data = {
      page: page,
      limit: CONSTANT.PER_PAGE,
    };

    this.props.getCategories(data);
  };

  render() {
    const response = this.props.response;
    if (!response) {
      this.props.showLoading(true);
      return <div></div>;
    }

    this.props.showLoading(false);
    if (!response.success) {
      alertResponseErrors(response.errors);
    }

    const currentPage = this.state.currentPage;
    const totalItemCount =
      response && response.success ? response.total_count : 0;

    return (
      <Fragment>
        <Breadcrumb
          items={[
            {
              active: false,
              name: URLS.DASHBOARD_TEXT,
            },
          ]}
        />
        {this.renderTable()}
        <Pagination
          onClick={this.getCategories}
          totalItemCount={totalItemCount}
          currentPage={currentPage}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.category.getCategoriesResponse,
  };
};

const mapDispatchToProps = {
  getCategories: getCategories,
  showLoading: showLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
