import React from 'react';

import {CONSTANT} from "../../util/constant";

class Pagination extends React.Component {

    onClick = (page) => {
        this.props.onClick(page);
    }

    renderItems = () => {
        const totalItemCount = this.props.totalItemCount;
        const totalPage = Math.ceil(totalItemCount / CONSTANT.PER_PAGE);
        let array = [];
        for (let i = 0; i < totalPage; i++) {
            array.push(i + 1);
        }

        return array.map((item, index) => {
            const active = item === this.props.currentPage;
            return (
                <div
                    onClick={() => {
                        if(!active){
                            this.onClick(item)
                        }
                    }}
                    className={`item ${active ? 'active' : ''}`}
                    key={index}>
                    {item}
                </div>
            );
        });
    }

    render() {
        return (
            <div className="ui pagination menu">
                {this.renderItems()}
            </div>
        );
    }
}

export default Pagination;