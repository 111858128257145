const INITIAL_STATE = {
    user: null,
    token: null,
    errors: null
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'CHECK_LOGIN':
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token
            };
        case 'LOGIN':
            if (action.payload.response.success) {
                state = {
                    ...state,
                    user: action.payload.response.result.user,
                    token: action.payload.response.result.token
                }
            }

            return {
                ...state,
                response: action.payload.response,
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                token: null
            };
        default:
            return state;
    }
}
