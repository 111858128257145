import {Api, setData} from "../../util/api";

export const deleteFile = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'file/delete',
        setData(data, getState)
    );

    dispatch({
        type: 'DELETE_FILE',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}