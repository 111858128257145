import {setRTLTextPlugin} from "react-map-gl";

const INITIAL_STATE = {
    sideMenuIndex: 0,
    showLoading: false,
    showSideMenu: false,
    title: null,
    notifications: [],
    setMapBoxRTL: false,
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'HANDLE_LOADING':
            return {
                ...state,
                showLoading: action.payload.show
            };
        case 'HANDLE_SIDE_MENU':
            return {
                ...state,
                showSideMenu: action.payload.show
            };
        case 'CHANGE_SIDE_MENU_INDEX':
            const index = action.payload.sideMenuIndex;
            if (index == state.sideMenuIndex) {
                return {
                    ...state,
                    sideMenuIndex: -1
                };
            } else {
                return {
                    ...state,
                    sideMenuIndex: index
                };
            }
        case 'SET_TITLE':
            return {
                ...state,
                title: action.payload.title
            };
        case 'SET_NOTIFICATIONS':
            const notificationResponse = action.payload.response;
            if (notificationResponse && notificationResponse.success) {
                const result = notificationResponse.result;
                if (result) {
                    return {
                        ...state,
                        notifications: result
                    };
                }
            }
        default:
            return state;
    }
}

