import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./authReducer";
import siteReducer from "./siteReducer";
import modalReducer from "./modalReducer";
import fileReducer from "./fileReducer";
import packageReducer from "./packageReducer";
import categoryReducer from "./categoryReducer";
import zoneReducer from "./zoneReducer";
import logReducer from "./logReducer";

export const reducers = combineReducers({
  form: formReducer,
  auth: authReducer,
  site: siteReducer,
  modal: modalReducer,
  file: fileReducer,
  package: packageReducer,
  category: categoryReducer,
  zone: zoneReducer,
  log: logReducer
});
