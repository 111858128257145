import React, {Fragment} from 'react';
import {connect} from "react-redux";

import { showSideMenu} from "../../redux/actions/siteAction";
import {logout} from "../../redux/actions/authAction";

class TopMenu extends React.Component {

    setUserName = () => {
        if (this.props.user) {
            const user = this.props.user;
            return (
                <Fragment>
                    <span>
                        سلام {user.name + ' ' + user.last_name}
                    </span>
                </Fragment>
            );
        } else {
            return <Fragment></Fragment>;
        }
    }

    render() {
        return (
            <Fragment>
                <div className="ui grid padded">
                    <div className="two wide column mobile only">
                        <button className="ui compact icon button showSideMenu"
                                onClick={() => {
                                    this.props.handleSideMenu(true)
                                }}>
                            <i className="bars icon showSideMenu"></i>
                        </button>
                    </div>
                    <div className="eight wide column">
                        {this.setUserName()}
                    </div>
                    <div className="six wide column left floated left aligned">
                        <div className="ui compact menu">
                            <div className="item">
                                <button className="circular ui icon button"
                                        onClick={() => {
                                            this.props.logout()
                                        }}
                                >
                                    <i className="icon logout"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ui divider"></div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
}

const mapDispatchToProps = {
    logout: logout,
    handleSideMenu: showSideMenu,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopMenu)
