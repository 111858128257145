import React from 'react';

class LoadingBtn extends React.Component {

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <button
                onClick={this.onClick}
                className={`ui button center aligned ${this.props.loading ? 'loading' : ''}
                ${this.props.className ? this.props.className : ''}`}
            >
                {this.props.text}
            </button>
        );
    }
}

export default LoadingBtn;