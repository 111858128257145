const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'GET_PACKAGES':
            return {
                ...state,
                getPackagesResponse: action.payload.response,
            };
        case 'GET_PACKAGE':
            return {
                ...state,
                getPackageResponse: action.payload.response,
            };
        case 'CREATE_PACKAGE':
            return {
                ...state,
                createPackageResponse: action.payload.response,
            };
        case 'UPDATE_PACKAGE':
            return {
                ...state,
                updatePackageResponse: action.payload.response,
            };
        case 'DELETE_PACKAGE':
            return {
                ...state,
                deletePackageResponse: action.payload.response,
            };
        case 'CLEAR_PACKAGE':
            return {
                ...state,
                getPackageResponse: null,
            };
        default:
            return state;
    }
}
