const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ZONES":
      return {
        ...state,
        getZonesResponse: action.payload.response,
      };
    case "GET_ZONE":
      return {
        ...state,
        getZoneResponse: action.payload.response,
      };
    case "CREATE_ZONE":
      return {
        ...state,
        createZoneResponse: action.payload.response,
      };
    case "UPDATE_ZONE":
      return {
        ...state,
        updateZoneResponse: action.payload.response,
      };
    case "DELETE_ZONE":
      return {
        ...state,
        deleteZoneResponse: action.payload.response,
      };
    case "CLEAR_ZONE":
      return {
        ...state,
        getZoneResponse: null,
      };
    default:
      return state;
  }
};
