const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'DELETE_FILE':
            return {
                ...state,
                deleteFileResponse: action.payload.response,
            };
        default:
            return state;
    }
}