import React, { Fragment } from "react";
import { connect } from "react-redux";

import Breadcrumb from "../layout/Breaadcrumb";
import { URLS } from "../../util/constant";
import Package from "./Package";

class PackageCreate extends React.Component {
  render() {    
    const categoryId = this.props.match.params.categoryId;

    return (
      <Fragment>
        <Breadcrumb
          items={[
            {
              active: true,
              name: URLS.DASHBOARD_TEXT,
              url: URLS.DASHBOARD,
            },
            {
              active: true,
              name: URLS.PACKAGE_LIST_TEXT,
              url: URLS.PACKAGE_LIST,
            },
            {
              active: false,
              name: URLS.PACKAGE_CREATE_TEXT,
            },
          ]}
        />
        <Package categoryId={categoryId} history={this.props.history} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PackageCreate);
