import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Tab } from "semantic-ui-react";

import { alertResponseErrors, error, success } from "../../util/alert";
import {
  renderDropdownSemantic,
  renderInput,
  renderTextArea,
} from "../../util/formInput";
import LoadingBtn from "../layout/LoadingBtn";
import { showLoading } from "../../redux/actions/siteAction";
import File from "../layout/File";
import { create, update } from "../../redux/actions/packageAction";
import { getZones } from "../../redux/actions/zoneAction";
import { options } from "numeral";

class Package extends React.Component {
  state = {
    inSending: false,
    files: [],
    selectedZones: [],
  };

  componentDidMount() {
    this.props.getZones();
    const model = this.props.model;
    if (model) {
      this.props.initialize({
        version: model.version,
        versionName: model.version_name,
        description: model.description,
      });
    }
  }

  validateForm = (data) => {
    let hasError = false;
    const model = this.props.model;

    if (!data.version) {
      hasError = true;
      error("لطفاً ورژن را وارد کنید");
    }

    if (!data.version_name) {
      hasError = true;
      error("لطفاً نام ورژن را وارد کنید");
    }

    const files = this.state.files;
    if (!files.length) {
      hasError = true;
      error("لطفاً فایل را وارد کنید");
    }

    return !hasError;
  };

  onSubmit = (data) => {
    if (this.state.inSending === true) {
      return;
    }

    const validateResult = this.validateForm(data);
    if (!validateResult) {
      return;
    }

    this.setState({
      inSending: true,
    });

    let formData = new FormData();
    formData.append("version", data.version);
    formData.append("version_name", data.version_name);
    formData.append("description", data.description ? data.description : "");
    formData.append("category_id", this.props.categoryId);

    //set files
    const files = this.state.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file[" + i + "]", files[i]);
      }
    }

    //set zones
    const zones = this.state.selectedZones;
    if (zones.length > 0) {
      for (let i = 0; i < zones.length; i++) {
        formData.append("zones[" + i + "]", zones[i]);
      }
    }

    if (this.props.model) {
      formData.append("id", this.props.model.id);
      this.props.update(formData, this.onResponse);
    } else {
      this.props.create(formData, this.onResponse);
    }
  };

  onResponse = (response) => {
    this.setState({
      inSending: false,
    });

    if (response.success) {
      success("محتوا با موفقیت ثبت/ویرایش شد");
      this.props.history.goBack();
    } else {
      alertResponseErrors(response.errors);
    }
  };

  onFileSelect = (files) => {
    this.setState({
      files: files,
    });
  };

  renderPackageForm = (model) => {
    const zonesResponse = this.props.zones;
    if (!zonesResponse || !zonesResponse.success) {
      return <div />;
    }

    const zones = zonesResponse.result.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.title,
    }));

    return (
      <Tab.Pane attached={false}>
        <div className="ui grid two column stackable">
          <div className="row">
            <div className="column">
              <Field
                name="version"
                label="ورژن"
                placeholder="ورژن"
                component={renderInput}
              />
            </div>

            <div className="column">
              <Field
                name="version_name"
                label="نام ورژن"
                placeholder="نام ورژن"
                component={renderInput}
              />
            </div>
          </div>
        </div>

        <div className="ui grid one column stackable">
          <div className="row">
            <div className="column">
              {renderDropdownSemantic(
                "مناطق فعال برای این پکیج",
                zones,
                (target, data) => {
                  this.setState({
                    selectedZones: data.value,
                  });
                },
                true,
                this.state.selectedZones
              )}
            </div>
          </div>
        </div>

        <div className="ui grid one column stackable">
          <div className="row">
            <div className="column">
              <Field
                name="description"
                label="توضیحات"
                placeholder="توضیحات"
                component={renderTextArea}
              />
            </div>
          </div>
        </div>

        <div className="ui grid one column stackable">
          <div className="row">
            <div className="column">
              <div className="field">
                <label>فایل</label>
                <File
                  accept="*"
                  files={model ? model.files : null}
                  onFileSelect={this.onFileSelect}
                />
              </div>
            </div>
          </div>
        </div>
      </Tab.Pane>
    );
  };

  render() {
    const model = this.props.model;

    const panes = [
      {
        menuItem: "پکیج",
        render: () => {
          return this.renderPackageForm(model);
        },
      },
    ];

    return (
      <div className="">
        <form
          className="ui form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Tab menu={{ pointing: true }} panes={panes} />

          <div className="ui grid three column">
            <div className="column">
              <LoadingBtn
                loading={this.state.inSending}
                text={this.props.model ? "ویرایش" : "ثبت"}
                onClick={() => {
                  this.props.handleSubmit(this.onSubmit);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    zones: state.zone.getZonesResponse,
  };
};

const mapDispatchToProps = {
  create: create,
  update: update,
  showLoading: showLoading,
  getZones: getZones,
};

Package = connect(mapStateToProps, mapDispatchToProps)(Package);

export default reduxForm({
  form: "create_update_package",
})(Package);
