export const handleSimpleModal = (
    show,
    title,
    description,
    onClick,
    onClose
) => ({
    type: 'HANDLE_SIMPLE_MODAL',
    payload: {
        show: show,
        title: title,
        description: description,
        onClick: onClick,
        onClose: onClose,
    }
})

export const handleTextModal = (
    show,
    title,
    description
) => ({
    type: 'HANDLE_TEXT_MODAL',
    payload: {
        show: show,
        title: title,
        description: description
    }
})