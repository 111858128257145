import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {login, checkLogin} from "../../redux/actions/authAction";
import {alertResponseErrors, error} from "../../util/alert";
import LoadingBtn from "../layout/LoadingBtn";
import {renderInput} from "../../util/formInput";

class Login extends React.Component {

    state = {
        inSending: false,
    }

    formValidation = (data) => {
        let hasError = false;

        if (!data.username) {
            error('نام کاربری را وارد کنید');
            hasError = true;
        }

        if (!data.password) {
            error('کلمه عبور را وارد کنید');
            hasError = true;
        }

        return !hasError;
    }

    onSubmit = data => {
        if (this.state.inSending === true) {
            return;
        }

        const validateResult = this.formValidation(data);
        if (!validateResult) {
            return;
        }

        this.setState({
            inSending: true,
        });

        this.props.login(
            data.username,
            data.password,
            this.onResponse
        );
    }

    onResponse = (response) => {
        this.setState({
            inSending: false,
        });

        if (!response.success) {
            alertResponseErrors(response.errors);
        } else {
            this.props.checkLogin();
        }
    }

    render() {
        return (
            <div style={{marginTop: "10px"}}>
                <div className="ui center aligned grid two column">
                    <div className="column">
                        <h2 className="ui teal center aligned header">
                            ورود به پنل کاربری
                        </h2>
                        <form className="ui large form"
                              onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <div className="ui stacked segment">
                                <Field name="username"
                                       type="text"
                                       icon="user icon"
                                       placeholder="نام کاربری"
                                       component={renderInput}
                                />
                                <Field name="password"
                                       type="password"
                                       icon="lock icon"
                                       placeholder="کلمه عبور"
                                       component={renderInput}
                                />

                                <LoadingBtn
                                    loading={this.state.inSending}
                                    text="ورود"
                                    onClick={() => {
                                        this.props.handleSubmit(this.onSubmit)
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

}

Login = connect(
    mapStateToProps,
    {
        login,
        checkLogin
    }
)(Login);

export default reduxForm(
    {
        form: 'login',
    }
)(Login);
