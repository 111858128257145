import React, { Fragment } from "react";
import { connect } from "react-redux";

import { CONSTANT, URLS } from "../../util/constant";
import Pagination from "../layout/Pagination";
import { alertResponseErrors, success } from "../../util/alert";
import { handleSimpleModal } from "../../redux/actions/modalAction";
import { showLoading } from "../../redux/actions/siteAction";
import Breadcrumb from "../layout/Breaadcrumb";
import { renderDate } from "../../util/helper";
import { deletePackage, getPackages } from "../../redux/actions/packageAction";
import { Link } from "react-router-dom";

class PackageList extends React.Component {
  state = {
    currentPage: 1,
  };

  componentDidMount() {
    this.getPackages(this.state.currentPage);
  }

  renderTable = () => {
    return (
      <Fragment>
        <table className="ui selectable celled table right aligned">
          <thead>
            <tr>
              <th>ورژن</th>
              <th>نام ورژن</th>
              <th>تاریخ ثبت</th>
              <th>مناطق</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </Fragment>
    );
  };

  renderRows() {
    const response = this.props.response;
    if (!response || !response.success) {
      return <div></div>;
    }

    const packages = response.result;
    if (!packages) {
      return <div></div>;
    }

    return packages.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.version}</td>
          <td>{item.version_name}</td>
          <td>{renderDate(item.creation_date)}</td>
          <td>
            {item.zones ? item.zones.map((zone) => zone.zone_title + ", ") : ""}
          </td>
          <td>
            <Fragment>
              <button
                className="circular ui icon button red"
                onClick={() => {
                  this.showDeleteModal(item.id);
                }}
              >
                <i className="icon trash"></i>
              </button>
            </Fragment>
          </td>
        </tr>
      );
    });
  }

  showDeleteModal = (id) => {
    this.props.simpleModal(
      true,
      "حذف پکیج",
      "آیا برای حذف پکیج اطمینان دارید؟",
      () => {
        this.deletePackage(id);
      },
      null
    );
  };

  deletePackage(id) {
    const data = {
      id: id,
    };

    this.props.deletePackage(data, this.onDeleteResponse);
    this.props.showLoading(true);
  }

  onDeleteResponse = (response) => {
    this.props.showLoading(false);
    if (response.success) {
      success("پکیج با موفقیت حذف شد");
      this.getPackages(this.state.currentPage);
    } else {
      alertResponseErrors(response.errors);
    }
  };

  getPackages = (page) => {
    const categoryId = this.props.match.params.categoryId;
    this.setState({
      currentPage: page,
    });

    const data = {
      page: page,
      category_id: categoryId,
      limit: CONSTANT.PER_PAGE,
    };

    this.props.getPackages(data);
  };

  render() {
    const response = this.props.response;
    if (!response) {
      this.props.showLoading(true);
      return <div></div>;
    }

    this.props.showLoading(false);
    if (!response.success) {
      alertResponseErrors(response.errors);
    }

    const currentPage = this.state.currentPage;
    const totalItemCount =
      response && response.success ? response.total_count : 0;

    const categoryId = this.props.match.params.categoryId;
    const createLink = URLS.PACKAGE_CREATE.replace(":categoryId", categoryId);
    return (
      <Fragment>
        <div className="ui raised segment">
          <Link to={createLink} className="ui button center aligned green">
            {URLS.PACKAGE_CREATE_TEXT}
          </Link>
        </div>
        <Breadcrumb
          items={[
            {
              active: true,
              name: URLS.DASHBOARD_TEXT,
              url: URLS.DASHBOARD,
            },
            {
              active: false,
              name: URLS.PACKAGE_LIST_TEXT,
            },
          ]}
        />
        {this.renderTable()}
        <Pagination
          onClick={this.getPackages}
          totalItemCount={totalItemCount}
          currentPage={currentPage}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.package.getPackagesResponse,
  };
};

const mapDispatchToProps = {
  getPackages: getPackages,
  simpleModal: handleSimpleModal,
  showLoading: showLoading,
  deletePackage: deletePackage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageList);
