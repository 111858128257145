import {Api, setData} from "../../util/api";

export const getLogs = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'log/get-logs',
        setData(data, getState)
    );

    dispatch({
        type: 'GET_LOGS',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const getLog = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'log/get-log',
        setData(data, getState)
    );

    dispatch({
        type: 'GET_LOG',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const clear = () => ({
    type: 'CLEAR_LOG',
})
