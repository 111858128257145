export const CONSTANT = {
  SERVER_URL: "https://core.fardad-bop-panel.ir/admin/",
  SERVER: "https://core.fardad-bop-panel.ir/",

  PER_PAGE: 10,

  PACKAGE_STATUS_ACTIVE: 1,
  PACKAGE_STATUS_ACTIVE_TEXT: "فعال",
  PACKAGE_STATUS_DELETED: 2,
  PACKAGE_STATUS_DELETED_TEXT: "حذف شده",

  ZONE_STATUS_ACTIVE: 1,
  ZONE_STATUS_ACTIVE_TEXT: "فعال",
  ZONE_STATUS_DELETED: 2,
  ZONE_STATUS_DELETED_TEXT: "حذف شده",
  PACKAGE_STATUS_DELETED_TEXT: "حذف شده"
};

export const URLS = {
  DASHBOARD: "/",
  DASHBOARD_TEXT: "لیست دسته بندی ها",

  PACKAGE_LIST: "/:categoryId/packages",
  PACKAGE_LIST_TEXT: "لیست پکیج ها",
  PACKAGE_CREATE: "/:categoryId/package/create",
  PACKAGE_CREATE_TEXT: "ثبت پکیج",
  PACKAGE_UPDATE: "/:categoryId/package/update/:id",
  PACKAGE_UPDATE_TEXT: "ویرایش پکیج",

  ZONE_LIST: "/zones",
  ZONE_LIST_TEXT: "لیست منطقه ها",
  ZONE_CREATE: "/zone/create",
  ZONE_CREATE_TEXT: "ثبت منطقه",
  ZONE_UPDATE: "/zone/update/:id",
  ZONE_UPDATE_TEXT: "ویرایش منطقه",
  
  LOG_LIST: "/logs",
  LOG_LIST_TEXT: "لاگ ها",
  LOG_DETAIL: "/logs/detail/:id",
  LOG_DETAIL_TEXT: "مشاهده جزییات لاگ"
};

export const DB_KEYS = {
  USER: "admin_user",
  TOKEN: "admin_token"
};
