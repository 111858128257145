export const setData = (key, value) => {
    value = JSON.stringify(value);
    window.localStorage.setItem(key, value);
}

export const getData = (key) => {
    const value = window.localStorage.getItem(key);
    return JSON.parse(value);
}

export const clearData = (key) => {
    return window.localStorage.removeItem(key);
}

export const clear = () => {
    return window.localStorage.clear();
}