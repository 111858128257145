import React from "react";
import {Checkbox, Dropdown} from "semantic-ui-react";
import NumberFormat from 'react-number-format';

export const renderDropdown = (
    data
) => {
    return (
        <div className="field">
            <label>{data.label}</label>
            <select
                className="ui dropdown"
                {...data.input}
                onChange={value => {
                    data.input.onChange(value);
                    if (data.onChangeData) {
                        data.onChangeData(value, value.target.value);
                    }
                }}
            >
                {
                    data.options.map(item => {
                        return (
                            <option key={item.key}
                                    value={item.value}>
                                {item.text}
                            </option>
                        );
                    })
                }
            </select>
        </div>
    );
}

/**
 *
 * @param label
 * @param options option item keys = key, text, value
 * @param onChange
 * @param multiple
 * @param defaultValues
 * @returns {*}
 */
export const renderDropdownSemantic = (
    label,
    options,
    onChange,
    multiple = false,
    defaultValues = null,
    name = null,
    allowAdditions = false,
    onAdd = null
) => {
    return (
        <div className="field">
            <label>{label}</label>
            <Dropdown
                //defaultValue={defaultValues}
                fluid
                selection
                multiple={multiple}
                allowAdditions={allowAdditions}
                onChange={onChange}
                search
                value={defaultValues}
                name={name}
                options={options}
                onAddItem={onAdd}
            />
        </div>
    );
}

export const renderInput = (data) => {
    return (
        <div className="field">
            <label>{data.label}</label>
            <input type={data.type ? data.type : 'text'}
                   placeholder={data.placeholder}
                   disabled={data.disabled}
                   {...data.input}
            />
        </div>
    );
}

export const renderInputNumberFormat = (data) => {
    return (
        <div className="field">
            <label>{data.label}</label>
            <NumberFormat
                {...data.input}
                disabled={data.disabled}
                placeholder={data.placeholder}
                thousandSeparator={true}/>
        </div>
    );
}

export const renderLabel = (data) => {
    return (
        <div className="field">
            <label>{data.label}</label>
            <input type={data.type ? data.type : 'text'}
                   placeholder={data.placeholder}
                   disabled={data.disabled}
                   {...data.input}
            />
        </div>
    );
}

export const renderHiddenInput = (data) => {
    return (
        <div className="field">
            <label>{data.label}</label>
            <input type="hidden"
                   placeholder={data.placeholder}
                   {...data.input}
            />
        </div>
    );
}

export const renderTextArea = (data) => {
    return (
        <div className="field">
            <label>{data.label}</label>
            <textarea
                placeholder={data.placeholder}
                {...data.input}
            ></textarea>
        </div>
    );
}

export const renderLoader = () => {
    return (
        <div className="ui active inverted dimmer">
            <div className="ui large text loader">لطفاً چند لحظه منتظر بمانید، در حال بارگذاری اطلاعات...</div>
        </div>
    );
}

export const renderCheckbox = (
    label,
    checked = false,
    onChange = null
) => {
    return (
        <div className="field">
            <Checkbox
                label={label}
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
}