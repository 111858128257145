import React, {Fragment} from 'react';
import {Button, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";

import {handleTextModal} from "../../redux/actions/modalAction";

class TextModal extends React.Component {

    closeModal = () => {
        this.props.handel(false);
    }

    render() {
        return (
            <Fragment>
                <Modal
                    open={this.props.textModal.show}
                >
                    <Modal.Header>
                        {this.props.textModal.title}
                    </Modal.Header>
                    <Modal.Content>
                        <p>{this.props.textModal.description}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.closeModal}
                            negative>
                            بستن
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    textModal: state.modal.textModal
})

const mapDispatchToProps = {
    handel: handleTextModal
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextModal)