import React from 'react';
import {connect} from "react-redux";
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";
import 'react-toastify/dist/ReactToastify.css';

import {checkLogin} from "../../redux/actions/authAction";
import Login from '../login/Login';
import Dashboard from "../dashboard/Dashboard";
import CompanyLogo from "../../asset/images/avatar/logo.jpeg";

class App extends React.Component {

    componentDidMount() {
        this.props.checkLogin();
    }

    setHeader = () => {
        let title = 'پنل مدیریت';
        if (this.props.title) {
            title += ' | ' + this.props.title;
        }

        return (
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <link rel="shortcut icon" href={CompanyLogo}/>
            </Helmet>
        );
    }

    renderSection = () => {
        if (this.props.token) {
            return (
                <Dashboard/>
            );
        } else {
            return (
                <Login/>
            );
        }
    }

    render() {
        return (
            <div>
                {this.setHeader()}
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                {this.renderSection()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        title: state.site.title,
    };
}

const mapDispatchToProps = {
    checkLogin: checkLogin,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
