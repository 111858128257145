import React, { Fragment } from "react";
import { connect } from "react-redux";

import { CONSTANT, URLS } from "../../util/constant";
import Pagination from "../layout/Pagination";
import { alertResponseErrors } from "../../util/alert";
import { showLoading } from "../../redux/actions/siteAction";
import Breadcrumb from "../layout/Breaadcrumb";
import { Link } from "react-router-dom";
import { getLogs } from "../../redux/actions/logAction";

class LogList extends React.Component {
  state = {
    currentPage: 1
  };

  componentDidMount() {
    this.getLogs(this.state.currentPage);
  }

  renderTable = () => {
    return (
      <Fragment>
        <table className="ui selectable celled table right aligned">
          <thead>
            <tr>
              <th>شناسه</th>              
              <th>سریال</th>
              <th>تاریخ</th>
              <th>جزییات بیشتر</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </Fragment>
    );
  };

  renderRows() {
    const response = this.props.response;
    if (!response || !response.success) {
      return <div></div>;
    }

    const packages = response.result;
    if (!packages) {
      return <div></div>;
    }

    return packages.map((item) => {
      const url = URLS.LOG_DETAIL.replace(":id", item.id);
      return (
        <tr key={item.id}>
          <td>{item.id}</td>          
          <td>{item.meter_serial}</td>
          <td>{`${item.date} ${item.time}`}</td>      

          <td>
            <Fragment>
              <Link to={url}>
                <button
                  title="مشاهده جزییات"
                  className="circular ui icon button blue"
                >
                  <i className="icon eye"></i>
                </button>
              </Link>
            </Fragment>
          </td>
        </tr>
      );
    });
  }

  getLogs = (page) => {
    this.setState({
      currentPage: page
    });

    const data = {
      page: page,
      limit: CONSTANT.PER_PAGE
    };

    this.props.getLogs(data);
  };

  render() {
    const response = this.props.response;
    if (!response) {
      this.props.showLoading(true);
      return <div></div>;
    }

    this.props.showLoading(false);
    if (!response.success) {
      alertResponseErrors(response.errors);
    }

    const currentPage = this.state.currentPage;
    const totalItemCount =
      response && response.success ? response.total_count : 0;

    return (
      <Fragment>
        <Breadcrumb
          items={[
            {
              active: true,
              name: URLS.DASHBOARD_TEXT,
              url: URLS.DASHBOARD
            },
            {
              active: false,
              name: URLS.LOG_LIST_TEXT
            }
          ]}
        />
        {this.renderTable()}
        <Pagination
          onClick={this.getLogs}
          totalItemCount={totalItemCount}
          currentPage={currentPage}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.log.getLogsResponse
  };
};

const mapDispatchToProps = {
  getLogs: getLogs,
  showLoading: showLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(LogList);
