import {Api, setData} from "../../util/api";

export const getPackages = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'package/get-packages',
        setData(data, getState)
    );

    dispatch({
        type: 'GET_PACKAGES',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const getPackage = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'package/get-package',
        setData(data, getState)
    );

    dispatch({
        type: 'GET_PACKAGE',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const create = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'package/create',
        setData(data, getState)
    );

    dispatch({
        type: 'CREATE_PACKAGE',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const update = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'package/update',
        setData(data, getState)
    );

    dispatch({
        type: 'CREATE_PACKAGE',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const deletePackage = (data, onResponse) => async (dispatch, getState) => {
    const response = await Api.post(
        'package/delete',
        setData(data, getState)
    );

    dispatch({
        type: 'DELETE_PACKAGE',
        payload: {
            response: response.data,
        }
    })

    if (onResponse) {
        onResponse(response.data);
    }
}

export const clear = () => ({
    type: 'CLEAR_PACKAGE',
})
