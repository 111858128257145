const INITIAL_STATE = {
    simpleModal: {
        title:'',
        description:'',
        onClick:null,
        onClose:'',
    },
    textModal:{
        title:'',
        description:''
    }
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'HANDLE_SIMPLE_MODAL':
            return {
                ...state,
                simpleModal: {
                    show:action.payload.show,
                    title:action.payload.title,
                    description:action.payload.description,
                    onClick:action.payload.onClick,
                    onClose:action.payload.onClose,
                }
            };
        case 'HANDLE_TEXT_MODAL':
            return {
                ...state,
                textModal: {
                    show:action.payload.show,
                    title:action.payload.title,
                    description:action.payload.description,
                }
            };
        default:
            return state;
    }
}