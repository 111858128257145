class PersianDate {
    static J_WEEK_SATURDAY = 0;
    static J_WEEK_SUNDAY = 1;
    static J_WEEK_MONDAY = 2;
    static J_WEEK_TUESDAY = 3;
    static J_WEEK_WEDNESDAY = 4;
    static J_WEEK_THURSDAY = 5;
    static J_WEEK_FRIDAY = 6;

    static J_MONTH_FARVARDIN = 0;
    static J_MONTH_ORDIBEHESHT = 1;
    static J_MONTH_KHORDAD = 2;
    static J_MONTH_TIR = 3;
    static J_MONTH_MORDAD = 4;
    static J_MONTH_SHAHRIVAR = 5;
    static J_MONTH_MEHR = 6;
    static J_MONTH_ABAN = 7;
    static J_MONTH_AZAR = 8;
    static J_MONTH_DEY = 9;
    static J_MONTH_BAHMAN = 10;
    static J_MONTH_ESFAND = 11;

    static GregorianLeapMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    static GregorianMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    static PersianLeapMonth = [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 30];
    static PersianMonth = [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 29];

    static DIF_GREGORIAN_FIRST_DAY_WITH_PERSIAN = 78;  // Difference between gregorian and persian in first days of persian calendar
    static DIF_PERSIAN_LAST_DAY_WITH_GREGORIAN = 287; // Difference between persian and gregorian in last days of persian calendar
    static DIF_PERSIAN_FIRST_DAY_WITH_GREGORIAN = 80; // Difference between persian and gregorian in fast days of persian calendar
    static DIF_GREGORIAN_YEAR_WITH_PERSIAN = 621; //Difference between gregorian and persian years

    static isPersianLeapYear(year) {
        return ((((((year - ((year > 0) ? 474 : 473)) % 2820) + 474) + 38) * 682) % 2816) < 682;
    }

    static isGregorianLeapYear(year) {
        return ((year % 4) == 0) && (!(((year % 100) == 0) && ((year % 400) != 0)));
    }

    static getDayOfYear(month, day, monthArray) {
        let doy = 0; //Day of Month
        for (let i = 0; i < month ; i++) {
            doy += monthArray[i];
        }
        doy += day;
        return doy;
    }

    static getDateAndMonth(doy, monthArray) {
        var result = []; // first index : day, second index : month
        var arraySize = monthArray.length;

        if (doy === 0) {
            result[0] = 1;
            result[1] = 1;
            return result;
        }

        for (let index = 0; index < arraySize; index++) {
            doy -= monthArray[index];
            if (doy < 0) {
                result[0] = doy + monthArray[index];
                result[1] = index + 1;
                return result;
            } else if (doy == 0) {
                result[0] = monthArray[index]; //last of month
                result[1] = index + 1;
                return result;
            }
        }

        return result;
    }

    static getPersianFromGregorian(gYear, gMonth, gDay) {
        var isGregorianLeapYearVar = PersianDate.isGregorianLeapYear(gYear);
        var isPersianLeapYearVar = false;
        var gDoy = 0; //Day of Month in gregorian
        var jDoy = 0; //Day of Month in persian
        var jYear = 0;
        var jMonth = 0;
        var jDay = 0;


        if (isGregorianLeapYearVar) {
            gDoy = PersianDate.getDayOfYear(gMonth, gDay, PersianDate.GregorianLeapMonth);
        } else {
            gDoy = PersianDate.getDayOfYear(gMonth, gDay, PersianDate.GregorianMonth);
        }

        if (gDoy > PersianDate.DIF_GREGORIAN_FIRST_DAY_WITH_PERSIAN) {
            jYear = gYear - PersianDate.DIF_GREGORIAN_YEAR_WITH_PERSIAN;
            isPersianLeapYearVar = PersianDate.isPersianLeapYear(jYear);
            if (isGregorianLeapYearVar) {
                jDoy = gDoy - PersianDate.DIF_GREGORIAN_FIRST_DAY_WITH_PERSIAN;
            } else {
                jDoy = gDoy - PersianDate.DIF_GREGORIAN_FIRST_DAY_WITH_PERSIAN - 1;
            }
        } else {
            jYear = gYear - PersianDate.DIF_GREGORIAN_YEAR_WITH_PERSIAN - 1;
            isPersianLeapYearVar = PersianDate.isPersianLeapYear(jYear);
            if (isPersianLeapYearVar) {
                jDoy = gDoy + PersianDate.DIF_PERSIAN_LAST_DAY_WITH_GREGORIAN;
            } else {
                jDoy = gDoy + PersianDate.DIF_PERSIAN_LAST_DAY_WITH_GREGORIAN - 1;
            }
        }

        var dayAndMonth = [];
        if (isPersianLeapYearVar) {
            dayAndMonth = PersianDate.getDateAndMonth(jDoy, PersianDate.PersianLeapMonth);
        } else {
            dayAndMonth = PersianDate.getDateAndMonth(jDoy, PersianDate.PersianMonth);
        }

        jDay = dayAndMonth[0];
        jMonth = dayAndMonth[1];

        return [jYear, jMonth, jDay];
    }

    static getGregorianDateFromJajali(jYear, jMonth, jDay) {
        let isPersianLeapYearVar = PersianDate.isPersianLeapYear(jYear);
        let isGregorianLeapYearVar = false;
        let jDoy = 0; //Day od Month in jalali
        let gDoy = 0; //Day od Month in gregorian
        let gYear = 0;
        let gMonth = 0;
        let gDay = 0;

        if (isPersianLeapYearVar) {
            jDoy = PersianDate.getDayOfYear(jMonth, jDay, PersianDate.PersianLeapMonth);
        } else {
            jDoy = PersianDate.getDayOfYear(jMonth, jDay, PersianDate.PersianMonth);
        }

        if (jDoy >= PersianDate.DIF_PERSIAN_LAST_DAY_WITH_GREGORIAN) {
            gYear = jYear + PersianDate.DIF_GREGORIAN_YEAR_WITH_PERSIAN + 1;
            isGregorianLeapYearVar = PersianDate.isGregorianLeapYear(gYear - 1);
            if (isGregorianLeapYearVar) {
                gDoy = jDoy - PersianDate.DIF_PERSIAN_LAST_DAY_WITH_GREGORIAN;
            } else {
                gDoy = jDoy - PersianDate.DIF_PERSIAN_LAST_DAY_WITH_GREGORIAN + 1;
            }
        } else {
            gYear = jYear + PersianDate.DIF_GREGORIAN_YEAR_WITH_PERSIAN;
            gDoy = jDoy + PersianDate.DIF_PERSIAN_FIRST_DAY_WITH_GREGORIAN - 1;
        }

        isGregorianLeapYearVar = PersianDate.isGregorianLeapYear(gYear);
        let dateAndMonth = [];
        if (isGregorianLeapYearVar) {
            dateAndMonth = PersianDate.getDateAndMonth(gDoy, PersianDate.GregorianLeapMonth);
        } else {
            dateAndMonth = PersianDate.getDateAndMonth(gDoy, PersianDate.GregorianMonth);
        }

        gDay = dateAndMonth[0];
        gMonth = dateAndMonth[1];
        return [gYear, gMonth, gDay];
    }

    static getPersianMonthDays(persianYear, persianMonth) {
        var leapYear = PersianDate.isPersianLeapYear(persianYear);

        if (leapYear) {
            return PersianDate.PersianLeapMonth[persianMonth];
        } else {
            return PersianDate.PersianMonth[persianMonth];
        }
    }

    static getPersianMonthName() {
        return [
            'فروردین',
            'اردیبهشت',
            'خرداد',
            'تیر',
            'مرداد',
            'شهریور',
            'مهر',
            'آبان',
            'آذر',
            'دی',
            'بهمن',
            'اسفند',
        ];
    }

    static getGegorianMonthName() {
        return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
    }

    static getPersianMonthNameItem(month) {
        month--;
        const months = PersianDate.getPersianMonthName();
        if (months[month] !== undefined) {
            return PersianDate.getPersianMonthName()[month];
        } else {
            return '';
        }
    }

    static getGregorianMonthNameItem(month) {
        month--;
        const months = PersianDate.getGegorianMonthName();
        if (months[month] !== undefined) {
            return PersianDate.getGegorianMonthName()[month];
        } else {
            return '';
        }
    }

    static getPersianDateFromGregorianTimeStamp(timeStamp, format) {
        try {
            let date = new Date(timeStamp);
            let persianDate = PersianDate.getPersianFromGregorian(date.getFullYear(), date.getMonth(), date.getDate());
            if (format) {
                switch (format) {
                    case 'YYYY MM dd':
                        return persianDate[0] + ' ' + PersianDate.getPersianMonthNameItem(persianDate[1]) + ' ' + persianDate[2];
                    case 'dd MM YYYY':
                        return persianDate[2] + ' ' + PersianDate.getPersianMonthNameItem(persianDate[1]) + ' ' + persianDate[0];
                    case 'Y':
                        return persianDate[2];
                    case 'YYYY/MM/dd':
                        return persianDate[0] + '/' + persianDate[1] + '/' + persianDate[2];
                    default :
                        return persianDate[0] + '/' + persianDate[1] + '/' + persianDate[2] + ' ' + date.getHours() + ':' + date.getMinutes();
                }
            } else {
                return persianDate[0] + '/' + persianDate[1] + '/' + persianDate[2] + ' ' + date.getHours() + ':' + date.getMinutes();
            }
        } catch (error) {
            return [];
        }
    }

    static getGregorianDateFromPersianDate(date, asTimeStamp) {
        try {
            let dateArray = date.split("/");
            let jYear = dateArray[0];
            let jMonth = dateArray[1];
            let jDay = dateArray[2];

            let gregorianDateArray = PersianDate.getGregorianDateFromJajali(jYear, jMonth, jDay);
            let gYear = gregorianDateArray[0];
            let gMonth = gregorianDateArray[1];
            let gDay = gregorianDateArray[2];
            let gregorianDate = new Date(gYear, gMonth, gDay);
            if (asTimeStamp) {
                return gregorianDate.getTime();
            } else {
                return gregorianDate;
            }
        } catch (error) {
            return false;
        }
    }

    static getDayOfWeekFromPersianDate(year, month, day) {
        const gDate = this.getGregorianDateFromJajali(year, month, day);
        const date = new Date(gDate[0], gDate[1]-1, gDate[2]);
        const weekDayName = [
            'یکشنبه',
            'دوشنبه',
            'سه شنبه',
            'چهارشنبه',
            'پنج شنبه',
            'جمعه',
            'شنبه',
        ];
        const weekDay = [
            1,
            2,
            3,
            4,
            5,
            6,
            0,
        ];

        return [
            weekDay[date.getDay()],
            weekDayName[date.getDay()],
        ];
    }

    static getGregorianDateFromPersianDateWithTime(dateAndTime, asTimeStamp) {
        try {
            let dateTimeArray = dateAndTime.split(" ");
            let dateArray = dateTimeArray[0].split("/");
            let timeArray = dateTimeArray[1].split(":");
            let jYear = dateArray[0];
            let jMonth = dateArray[1];
            let jDay = dateArray[2];
            let hour = timeArray[0];
            let min = timeArray[1];
            let sec = timeArray[2];

            let gregorianDateArray = PersianDate.getGregorianDateFromJajali(jYear, jMonth, jDay);
            let gYear = gregorianDateArray[0];
            let gMonth = gregorianDateArray[1];
            let gDay = gregorianDateArray[2];
            let date = new Date(gYear, gMonth, gDay, hour, min, sec);
            if (asTimeStamp) {
                return date.getTime();
            } else {
                return date;
            }
        } catch (error) {
            return false;
        }
    }
}

export default PersianDate;