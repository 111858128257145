import React from "react";
import { connect } from "react-redux";
import { Accordion, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

import CompanyLogo from "../../asset/images/avatar/logo.jpeg";
import { changeSideMenuIndex } from "../../redux/actions/siteAction";
import { URLS } from "../../util/constant";

class SideMenu extends React.Component {
  changeMenu = (e, titleProps) => {
    const { index } = titleProps;
    this.props.changeIndex(index);
  };

  isActive = (index) => {
    return this.props.menuIndex === index;
  };

  setCompany = () => {
    return (
      <p className="padded">
        <img
          src={CompanyLogo}
          className="ui tiny right spaced image padded"
          alt="فردادآزما"
        />
        &nbsp;فردادآزما
      </p>
    );
  };

  render() {
    const showSideMenu = this.props.showSideMenu;
    return (
      <div className={`sideMenu ${showSideMenu ? "active" : ""}`}>
        <div className="ui segment">{this.setCompany()}</div>
        <Accordion as={Menu} vertical fluid={true} className="fullHeight">
          {/*پکیج ها*/}
          <Menu.Item>
            <Accordion.Title
              index={0}
              active={this.isActive(0)}
              onClick={this.changeMenu}
            >
              <div className="item">
                <i className="setting icon"></i>
                <strong className="content">مدیریت پکیج ها</strong>
              </div>
            </Accordion.Title>
            <Accordion.Content active={this.isActive(0)}>
              <div className="ui list">
                <div className="item">
                  <div className="content">
                    <div className="ui list">
                      <div className="item">
                        <i className="circle icon"></i>
                        <div className="content">
                          <Link to={URLS.DASHBOARD}>{URLS.DASHBOARD_TEXT}</Link>
                        </div>
                      </div>

                      <div className="item">
                        <i className="circle icon"></i>
                        <div className="content">
                          <Link to={URLS.ZONE_LIST}>{URLS.ZONE_LIST_TEXT}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Content>
          </Menu.Item>

          {/*logs*/}
          <Menu.Item>
            <Accordion.Title
              index={1}
              active={this.isActive(1)}
              onClick={this.changeMenu}
            >
              <div className="item">
                <i className="setting icon"></i>
                <strong className="content">مدیریت لاگ ها</strong>
              </div>
            </Accordion.Title>
            <Accordion.Content active={this.isActive(1)}>
              <div className="ui list">
                <div className="item">
                  <div className="content">
                    <div className="ui list">
                      <div className="item">
                        <i className="circle icon"></i>
                        <div className="content">
                          <Link to={URLS.LOG_LIST}>{URLS.LOG_LIST_TEXT}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Content>
          </Menu.Item>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuIndex: state.site.sideMenuIndex,
    company: state.auth.company,
    showSideMenu: state.site.showSideMenu
  };
};

const mapDispatchToProps = {
  changeIndex: changeSideMenuIndex
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
