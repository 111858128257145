import { Api, setData } from "../../util/api";

export const getZones = (data, onResponse) => async (dispatch, getState) => {
  const response = await Api.post("zone/get-zones", setData(data, getState));

  dispatch({
    type: "GET_ZONES",
    payload: {
      response: response.data,
    },
  });

  if (onResponse) {
    onResponse(response.data);
  }
};

export const getZone = (data, onResponse) => async (dispatch, getState) => {
  const response = await Api.post("zone/get-zone", setData(data, getState));

  dispatch({
    type: "GET_ZONE",
    payload: {
      response: response.data,
    },
  });

  if (onResponse) {
    onResponse(response.data);
  }
};

export const createZone = (data, onResponse) => async (dispatch, getState) => {
  const response = await Api.post("zone/create", setData(data, getState));

  dispatch({
    type: "CREATE_ZONE",
    payload: {
      response: response.data,
    },
  });

  if (onResponse) {
    onResponse(response.data);
  }
};

export const updateZone = (data, onResponse) => async (dispatch, getState) => {
  const response = await Api.post("zone/update", setData(data, getState));

  dispatch({
    type: "UPDATE_ZONE",
    payload: {
      response: response.data,
    },
  });

  if (onResponse) {
    onResponse(response.data);
  }
};

export const deleteZone = (data, onResponse) => async (dispatch, getState) => {
  const response = await Api.post("zone/delete", setData(data, getState));

  dispatch({
    type: "DELETE_ZONE",
    payload: {
      response: response.data,
    },
  });

  if (onResponse) {
    onResponse(response.data);
  }
};

export const clearZone = () => ({
  type: "CLEAR_ZONE",
});
