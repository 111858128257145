import { toast } from 'react-toastify';

export const error = (text) =>{
    toast.error(text, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
}

export const success = (text) =>{
    toast.success(text, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
}

export const alertResponseErrors = (errors) =>{
    const arraySize = errors.length;
    for (let i = 0; i < arraySize; i++) {
        error(errors[i]);
    }
}