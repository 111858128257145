import React, { Fragment } from "react";
import { connect } from "react-redux";

import { showLoading } from "../../redux/actions/siteAction";
import { clearZone, getZone } from "../../redux/actions/zoneAction";
import { alertResponseErrors } from "../../util/alert";
import { URLS } from "../../util/constant";
import Breadcrumb from "../layout/Breaadcrumb";
import Zone from "./Zone";

class PackageUpdate extends React.Component {
  componentDidMount() {
    this.props.clear();
    const data = {
      id: this.props.match.params.id,
    };
    this.props.getZone(data);
  }

  render() {
    const response = this.props.response;
    if (!response) {
      this.props.showLoading(true);
      return <div></div>;
    }

    if (!response.success) {
      this.props.showLoading(false);
      alertResponseErrors(response.errors);
      return <div></div>;
    }

    this.props.showLoading(false);

    return (
      <Fragment>
        <Breadcrumb
          items={[
            {
              active: true,
              name: URLS.DASHBOARD_TEXT,
              url: URLS.DASHBOARD,
            },
            {
              active: true,
              name: URLS.ZONE_LIST_TEXT,
              url: URLS.ZONE_LIST,
            },
            {
              active: false,
              name: URLS.ZONE_UPDATE_TEXT,
            },
          ]}
        />
        <Zone model={response.result} history={this.props.history} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.zone.getZoneResponse,
  };
};

const mapDispatchToProps = {
  getZone: getZone,
  clear: clearZone,
  showLoading: showLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageUpdate);
