import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Tab } from "semantic-ui-react";

import { alertResponseErrors, error, success } from "../../util/alert";
import { renderInput } from "../../util/formInput";
import LoadingBtn from "../layout/LoadingBtn";
import { showLoading } from "../../redux/actions/siteAction";
import { createZone, updateZone } from "../../redux/actions/zoneAction";

class Zone extends React.Component {
  state = {
    inSending: false,
  };

  componentDidMount() {
    const model = this.props.model;
    if (model) {
      this.props.initialize({
        title: model.title,
      });
    }
  }

  validateForm = (data) => {
    let hasError = false;

    if (!data.title) {
      hasError = true;
      error("لطفاً عنوان را وارد کنید");
    }

    return !hasError;
  };

  onSubmit = (data) => {
    if (this.state.inSending === true) {
      return;
    }

    const validateResult = this.validateForm(data);
    if (!validateResult) {
      return;
    }

    this.setState({
      inSending: true,
    });

    let formData = new FormData();
    formData.append("title", data.title);

    if (this.props.model) {
      formData.append("id", this.props.model.id);
      this.props.update(formData, this.onResponse);
    } else {
      this.props.create(formData, this.onResponse);
    }
  };

  onResponse = (response) => {
    this.setState({
      inSending: false,
    });

    if (response.success) {
      success("محتوا با موفقیت ثبت/ویرایش شد");
      this.props.history.goBack();
    } else {
      alertResponseErrors(response.errors);
    }
  };

  renderZoneForm = (model) => {
    return (
      <Tab.Pane attached={false}>
        <div className="ui grid two column stackable">
          <div className="row">
            <div className="column">
              <Field
                name="title"
                label="عنوان"
                placeholder="عنوان"
                component={renderInput}
              />
            </div>
          </div>
        </div>
      </Tab.Pane>
    );
  };

  render() {
    const model = this.props.model;

    const panes = [
      {
        menuItem: "منطقه",
        render: () => {
          return this.renderZoneForm(model);
        },
      },
    ];

    return (
      <div className="">
        <form
          className="ui form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Tab menu={{ pointing: true }} panes={panes} />

          <div className="ui grid three column">
            <div className="column">
              <LoadingBtn
                loading={this.state.inSending}
                text={this.props.model ? "ویرایش" : "ثبت"}
                onClick={() => {
                  this.props.handleSubmit(this.onSubmit);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  create: createZone,
  update: updateZone,
  showLoading: showLoading,
};

Zone = connect(mapStateToProps, mapDispatchToProps)(Zone);

export default reduxForm({
  form: "create_update_zone",
})(Zone);
