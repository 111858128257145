const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_LOGS":
      return {
        ...state,
        getLogsResponse: action.payload.response
      };
    case "GET_LOG":
      return {
        ...state,
        getLogResponse: action.payload.response
      };
    case "CLEAR_LOG":
      return {
        ...state,
        getLogResponse: null
      };
    default:
      return state;
  }
};
