import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

import { showSideMenu } from "../../redux/actions/siteAction";
import { URLS } from "../../util/constant";
import TopMenu from "../layout/TopMenu";
import SimpleModal from "../modal/SimpleModal";
import SideMenu from "../layout/SideMenu";
import TextModal from "../modal/TextModal";
import PackageCreate from "../package/PackageCreate";
import PackageUpdate from "../package/PackageUpdate";
import PackageList from "../package/PackageList";
import CategoryList from "../category/CategoryList";
import ZoneList from "../zone/ZoneList";
import ZoneCreate from "../zone/ZoneCreate";
import ZoneUpdate from "../zone/ZoneUpdate";
import LogList from "../log/LogList";
import LogDetail from "../log/LogDetail";

class Dashboard extends React.Component {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  handleClick = (event) => {
    try {
      let isNotShowSideMenuBtn = true;
      const classes = event.target.className.split(" ");
      const arraySize = classes.length;
      for (let i = 0; i < arraySize; i++) {
        const className = classes[i];
        if (
          className === "showSideMenu" ||
          className === "title" ||
          className === "content"
        ) {
          isNotShowSideMenuBtn = false;
          break;
        }
      }

      if (isNotShowSideMenuBtn) {
        this.props.handleSideMenu(false);
      }
    } catch (e) {
      this.props.handleSideMenu(false);
    }
  };

  render() {
    return (
      <Router>
        <div className="ui grid stackable mainRtl">
          <div
            className="four wide column right aligned noPadding"
            id="sideMenuContainer"
          >
            <SideMenu />
          </div>
          <div className="twelve wide column">
            <TopMenu />
            <div className="ui container">
              <Route component={CategoryList} path={URLS.DASHBOARD} exact />
              <Route component={PackageList} path={URLS.PACKAGE_LIST} exact />
              <Route
                component={PackageCreate}
                path={URLS.PACKAGE_CREATE}
                exact
              />
              <Route
                component={PackageUpdate}
                path={URLS.PACKAGE_UPDATE}
                exact
              />
              {/* zones */}
              <Route component={ZoneList} path={URLS.ZONE_LIST} exact />
              <Route component={ZoneCreate} path={URLS.ZONE_CREATE} exact />
              <Route component={ZoneUpdate} path={URLS.ZONE_UPDATE} exact />

              {/* logs */}
              <Route component={LogList} path={URLS.LOG_LIST} exact />
              <Route component={LogDetail} path={URLS.LOG_DETAIL} exact />
            </div>
          </div>
        </div>

        <div
          className={`ui inverted dimmer ${
            this.props.showLoading ? "active" : ""
          }`}
        >
          <div className="ui large text loader">
            لطفاً چند لحظه منتظر بمانید، در حال بارگذاری اطلاعات...
          </div>
        </div>
        <SimpleModal />
        <TextModal />
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLoading: state.site.showLoading,
    user: state.auth.user
  };
};

const mapDispatchToProps = {
  handleSideMenu: showSideMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
